import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import "firebase/analytics";

import "./index.css";
import "./i18n";
import App from "./App";

firebase.initializeApp({
  apiKey: "AIzaSyDpgoKwKFKflPwZdE8zlHQbvlKUZXy0HVA",
  projectId: "bambswebsite",
  messagingSenderId: "935406328357",
  appId: "1:935406328357:web:51e9b2b095b535bae97dcc",
  measurementId: "G-P66LYXQ877"
});

ReactDOM.render(<App />, document.getElementById("root"));
